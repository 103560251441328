import { FC } from "react"
import { WrappedBase } from "../shared-components/Shared/WrappedBase"
import config from "../../config.json"
import { MortgageComparison } from "../views/MortgageComparison"
import { SEOVariantTag } from "../views/MortgageComparison/utils/types"

interface SEOData {
  title: string
  metaDescription: string
  amount: number | null
  propertyValue: number | null
  h1Heading: string
  introParagraph: string
  canonicalUrl: string
}

const defaultSeoData: SEOData = {
  title: "Compare mortgages: the best mortgage rates & deals | Habito",
  metaDescription:
    "Compare the best mortgage rates across the whole market – thousands of mortgages from over 90 lenders! Find your perfect mortgage now.",
  amount: null,
  propertyValue: null,
  h1Heading: "Compare mortgages",
  introParagraph:
    "See today's best mortgage rates, and compare the total cost over the initial term of different mortgages. A couple of disclaimers: first, we can't guarantee you'll be eligible for every deal in this table. Second, deals change constantly, so what you see here might not be available by the time you apply.",
  canonicalUrl: "https://www.habito.com/mortgage-comparison",
}

interface PageContext {
  metadata: SEOData
}

const ComparisonPage: FC<{
  pageContext: PageContext
}> = ({ pageContext }) => {
  const metadata = pageContext.metadata || defaultSeoData

  return (
    <WrappedBase
      metaTitle={metadata.title}
      metaDescription={metadata.metaDescription}
      canonicalUrl={metadata.canonicalUrl}
      noIndex={false}
      pageName="comparison_tool"
      intercom={true}
      config={config}
    >
      {metadata.amount !== null ? (
        <MortgageComparison
          seoVariant={{
            tag: SEOVariantTag.MortgageAmount,
            mortgageAmount: metadata.amount,
          }}
        />
      ) : (
        <MortgageComparison />
      )}
    </WrappedBase>
  )
}

export default ComparisonPage
